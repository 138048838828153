import React from 'react'
import { graphql } from 'gatsby'


import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import StoryBox from '../components/story-box'
import Button from '../components/button'
import SEO from '../components/seo'

import Layout from '../containers/layout'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query MyQuery {
    page: sanityPage(_id: {regex: "/(drafts.|)privacy/"}) {
      id
      title
     _rawBody
  }
}
`

const PrivacyPage = props => {
    const { data, errors } = props
  
    if (errors) {
      return (
        <Layout>
          <GraphQLErrorList errors={errors} />
        </Layout>
      )
    }
  
    const page = data && data.page
  
    if (!page) {
      throw new Error(
        'Missing "About" page data. Open the studio at http://localhost:3333 and add "Privacy Policy" page data and restart the development server.'
      )
    }
  
    return (
      <Layout>
        <SEO title={page.title} />
        <Container>
        <div className="inline-block relative  w-full  text-left md:text-center hill-background">
          <div className="inline-block w-full lg:w-1/2  pt-16  md:pt-32  relative">
            <PageHeader
              title={page.title || `Privacy Policy`}
              body={page._rawBody ? page._rawBody : undefined}
            />
          </div>
        </div>
         
        </Container>
      </Layout>
    )
  }
  
  export default PrivacyPage